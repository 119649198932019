<template>
    <div>
        <LoadingComponent :isLoading="loading" />
        <div class="shadow-lg rounded-lg bg-white flex items-center p-5 mb-3">
            <div class="grid grid-cols-12 gap-4 w-[100%]">
                <div class="col-span-10">
                    <div class="flex gap-3">
                        <div class="flex-1">
                            <label class="text-gray-400">
                                客訴單編號
                                <el-input v-model="filterOpts.id" size="small" clearable> </el-input>
                            </label>
                        </div>
                        <div class="flex-1 flex">
                            <label class="text-gray-400">
                                發起人手機
                                <div class="flex items-center">
                                    <div class="phone-select mr-2">
                                        <el-select v-model="filterOpts.phone_country" size="small" placeholder="请选择">
                                            <el-option v-for="item in phoneOptions" :key="item.value" :label="item.label" :value="item.value">
                                            </el-option>
                                        </el-select>
                                    </div>
                                    <el-input v-model="filterOpts.user_phone" size="small" clearable> </el-input>
                                </div>
                            </label>
                        </div>
                        <div class="flex-1">
                            <label class="text-gray-400">
                                成立日期 範圍
                                <el-date-picker
                                    v-model="dateInputs"
                                    style="height: 33px; width: 100%"
                                    size="small"
                                    type="daterange"
                                    value-format="yyyy-MM-dd"
                                    range-separator="至"
                                    start-placeholder="開始日期"
                                    end-placeholder="结束日期">
                                </el-date-picker>
                            </label>
                        </div>
                    </div>
                    <div class="flex gap-3">
                        <div class="flex-1">
                            <label class="text-gray-400">
                                問題分類
                                <el-select v-model="filterOpts.type" size="small" class="w-full" clearable>
                                    <el-option v-for="(type, index) in problemOptions" :key="index" :label="type.label" :value="type.value">
                                        {{ type.label }}
                                    </el-option>
                                </el-select>
                            </label>
                        </div>
                        <div class="flex-1">
                            <label class="text-gray-400">
                                緊急程度
                                <el-select v-model="filterOpts.severity" size="small" class="w-full" clearable>
                                    <el-option
                                        v-for="(severity, index) in severityOptions"
                                        :key="index"
                                        :label="severity.label"
                                        :value="severity.value">
                                        {{ severity.label }}
                                    </el-option>
                                </el-select>
                            </label>
                        </div>
                        <div class="flex-1">
                            <label class="text-gray-400">
                                狀態
                                <el-select v-model="filterOpts.status" size="small" class="w-full" clearable>
                                    <el-option v-for="(status, index) in statusOption" :key="index" :label="status.label" :value="status.value">
                                        {{ status.label }}
                                    </el-option>
                                </el-select>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="col-span-2 flex flex-col items-center justify-end">
                    <div class="flex w-full">
                        <button class="orange-btn-800 md:text-sm text-xs mr-1 w-[100%] h-[32px] flex-1" @click="goSearch">搜尋</button>
                        <button class="black-btn-outline md:text-sm text-xs w-[73px] h-[32px] flex-1" @click="reset">重置</button>
                    </div>
                    <div class="flex w-full mt-[35px]">
                        <button
                            v-permission="['create']"
                            class="orange-btn-outline-800 md:text-sm text-xs w-[200px] h-[32px] flex-1 mb-[2px]"
                            @click="addComplaint">
                            + 新增客訴
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="overflow-x-auto bg-white">
            <table class="myTable">
                <!-- <thead>
                    <tr>
                        <th>客訴單編號</th>
                        <th>狀態</th>
                        <th>發起人暱稱</th>
                        <th>成立時間</th>
                        <th>問題分類</th>
                        <th>緊急程度</th>
                        <th>查看</th>
                    </tr>
                </thead> -->
                <TableHead ref="TableHead" :labels="tableLabels" @changeSort="changeSort" />
                <tbody>
                    <tr v-for="i in datas" :key="i.id" class="text-center duration-200 transition">
                        <td>{{ i.id }}</td>
                        <td>{{ i.status | complaintStatus }}</td>
                        <td>{{ i.user.name }}</td>
                        <td>{{ i.created_at | formatDateTime }}</td>
                        <td>{{ i.type | complaintType }}</td>
                        <td>{{ i.severity | complaintSeverity }}</td>
                        <td class="cursor-pointer" @click="goEdit(i.id)">
                            <i class="far fa-edit"></i>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="justify-center mt-3 flex mb-3">
                <MyPagination
                    :paginationData="paginationData"
                    :currentPage="currentPage"
                    @onPageSizeChange="pageSizeChange"
                    @onCurrentPageChange="currentPageChange" />
            </div>
        </div>
        <PopupAddComplaint v-if="showDialog" v-model="showDialog" @success="addFinish" />
    </div>
</template>

<script>
// 分頁組件
import MyPagination from "@/components/MyPagination.vue";
// 旋轉動畫組件
import LoadingComponent from "@/components/Loading.vue";
// table Head
import TableHead from "@/components/table/TableHead.vue";
// 新增客訴彈窗
import PopupAddComplaint from "./components/PopupAddComplaint.vue";
// options
import orderComplaintConfig from "@/config/orderComplaintConfig";
import "@/global/filters/filterComplaint";
// options phone
import { phoneOptions } from "@/config/optionsConfig.js";
// init search Filter options
const defaultFilterOpts = {
    id: "",
    administrator_id: "",
    user_phone: "",
    phone_country: "886",
    created_at_start: "",
    created_at_end: "",
    type: "",
    severity: "",
    status: "",
    sort_by: "", // created_at or started_at or gross_price
    sort_type: "", // asc or desc
};
export default {
    name: "CustomerCompaintList",
    components: {
        LoadingComponent,
        MyPagination,
        TableHead,
        PopupAddComplaint,
    },
    computed: {
        // 統整 getlist api filters
        filterData() {
            const filter = {};
            for (const key in this.filterOpts) {
                if (this.filterOpts[key]) {
                    filter[key] = this.filterOpts[key];
                }
            }
            const result = {
                ...filter,
                user_phone: this.filterOpts.user_phone ? this.filterOpts.phone_country + this.filterOpts.user_phone : "",
                role: this.isProvider ? 1 : 0,
                limit: this.paginationData.limit,
                page: this.currentPage,
            };

            if (!this.filterOpts.user_phone) {
                delete result.user_phone;
            }

            return result;
        },
        // 問題 options
        problemOptions() {
            const status = orderComplaintConfig.complaintType;
            const result = [
                {
                    label: "全部",
                    value: "",
                },
            ];
            for (const key in status) {
                result.push({
                    label: status[key],
                    value: key,
                });
            }
            return result;
        },
        // 警急程度 options
        severityOptions() {
            const status = orderComplaintConfig.complaintSeverity;
            const result = [
                {
                    label: "全部",
                    value: "",
                },
            ];
            for (const key in status) {
                result.push({
                    label: status[key],
                    value: key,
                });
            }
            return result;
        },
        // 狀態
        statusOption() {
            const status = orderComplaintConfig.complaintStatus;
            const result = [
                {
                    label: "全部",
                    value: "",
                },
            ];
            for (const key in status) {
                result.push({
                    label: status[key],
                    value: key,
                });
            }
            return result;
        },
    },
    data() {
        return {
            loading: false,
            datas: [],
            paginationData: {
                limit: 10,
                total: 0,
            },
            currentPage: 1,
            filterOpts: { ...defaultFilterOpts }, // 篩選options
            dateInputs: [], // 時間範圍
            // table header
            tableLabels: [
                {
                    label: "客訴單編號",
                },
                {
                    label: "狀態",
                },
                {
                    label: "發起人暱稱",
                },
                {
                    label: "成立時間",
                    isSort: true,
                    val: "created_at",
                },
                {
                    label: "問題分類",
                },
                {
                    label: "緊急程度",
                },
                {
                    label: "查看",
                },
            ],
            showDialog: false,
            // 手機國碼
            phoneOptions() {
                return phoneOptions;
            },
        };
    },
    methods: {
        // 讀取 list api
        async getList() {
            this.loading = true;
            const { status, data } = await this.$api
                .SearchUserFeedbacks(this.filterData)
                // .customerComplaintApi(this.id, this.filterData)
                .then((res) => {
                    return { data: res.data, status: res.status };
                })
                .catch(() => {
                    this.$message({
                        type: "error",
                        message: "取得資料失敗",
                    });
                })
                .finally(() => {
                    this.loading = false;
                });
            if (status === 200) {
                this.datas = [...data.data];
                this.paginationData = {
                    limit: data.per_page,
                    total: data.total,
                };
            }
        },
        // 搜尋
        goSearch() {
            this.currentPage = 1;
            this.getList();
        },
        // 改變排序
        changeSort(val) {
            this.filterOpts.sort_by = val.sortBy;
            this.filterOpts.sort_type = val.orderBy;
            this.getList();
        },
        // 重置
        reset() {
            this.dateInputs = [];
            this.filterOpts = { ...defaultFilterOpts };
            this.$refs.TableHead.reset();
            this.currentPage = 1;
            this.paginationData = {
                limit: 10,
                total: 0,
            };
            this.getList();
        },
        // 切頁相關
        pageSizeChange(val) {
            // 更新每頁取得幾筆資料參數
            this.paginationData.limit = val;
            // 當前頁面設定為第一頁
            this.currentPage = 1;
            this.getList();
        },
        currentPageChange(val) {
            // 設定目前分頁為第幾面
            this.currentPage = val;
            this.getList();
        },
        // 到訂單細節頁,將此頁資訊帶入
        goEdit(customerID) {
            this.$router.push({
                name: "customer-complaint-detail",
                params: {
                    customerID,
                },
            });
        },
        addComplaint() {
            this.showDialog = true;
        },
        addFinish() {
            this.showDialog = false;
            this.getList();
        },
    },
    watch: {
        dateInputs(val) {
            if (val === null || val.length === 0) {
                this.filterOpts.created_at_start = "";
                this.filterOpts.created_at_end = "";
                return;
            }
            this.filterOpts.created_at_start = val[0];
            this.filterOpts.created_at_end = val[1];
        },
    },
    mounted() {
        this.getList();
    },
    activated() {
        this.getList();
    },
};
</script>

<style lang="scss" scoped>
label {
    color: #757575;
    font-size: 16px;
    font-weight: 400;
    line-height: 34px;
}

::v-deep .el-input__inner {
    max-width: 100%;
}

::v-deep .el-range-separator {
    width: 20px;
}
</style>
